<template>
    <div class="ma-2 pa-2">
        
        <v-card height="90vh" class="pa-3">
            <v-tabs v-model="startTab" ref="myAccountTabs" class="grey lighten-3">
                <v-tab class="grey lighten-3" href="#meusdados">
                    <v-icon left> mdi-account </v-icon>
                    Meus dados
                </v-tab>
                <v-tab class="grey lighten-3" href="#seguranca">
                    <v-icon left> mdi-lock </v-icon>
                    Segurança
                </v-tab>
                <v-tab-item value="meusdados">
                    <v-card flat class="ma-3 pa-3">
                        <v-card-text class="">
                            <v-card-text class="mb-3">
                                Informações básicas do sistema
                            </v-card-text>
                            <v-row>
                                <v-col cols="12" sm="12" md="6" lg="6">
                                    <v-row>
                                        <v-col cols="6" sm="12" md="5" lg="5">
                                            <v-card-text
                                                ><v-icon class="mx-1"
                                                    >mdi-information-outline</v-icon
                                                >Nome de usuário:</v-card-text
                                            >
                                        </v-col>
                                        <v-col cols="6" sm="12" md="5" lg="5">
                                            <v-text-field
                                                solo
                                                small
                                                readonly
                                                disabled
                                                v-model="computedGetUserName"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="6" sm="12" md="5" lg="5">
                                            <v-card-text
                                                ><v-icon class="mx-1"
                                                    >mdi-information-outline</v-icon
                                                >Login:</v-card-text
                                            >
                                        </v-col>
                                        <v-col cols="6" sm="12" md="5" lg="5">
                                            <v-text-field
                                                solo
                                                small
                                                readonly
                                                disabled
                                                v-model="computedGetUserLogin"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col sm="12" md="5" lg="5">
                                            <v-card-text
                                                ><v-icon class="mx-1"
                                                    >mdi-information-outline</v-icon
                                                >E-mail do usuário:</v-card-text
                                            >
                                        </v-col>
                                        <v-col sm="12" md="5" lg="5">
                                            <v-text-field
                                                solo
                                                small
                                                readonly
                                                disabled
                                                v-model="computedGetUserEmail"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item value="seguranca">
                    <v-card flat class="ma-3 pa-3">
                        <v-card-text>
                            <v-card-text class="mb-3">
                                Deseja redefinir sua senha? Utilize o campo
                                abaixo para criar uma nova senha:
                            </v-card-text>
                            <v-form
                                ref="form"
                                v-model="valid"
                                @keyup.native.enter="submit"
                            >
                                <v-row>
                                    <v-col sm="12" lg="3" xl="3">
                                        <v-card-text>
                                            <v-icon>mdi-lock</v-icon>
                                            Nova senha:</v-card-text
                                        >
                                    </v-col>
                                    <v-col sm="12" lg="4" xl="4">
                                        <v-text-field
                                            solo
                                            loading
                                            autocomplete="current-password"
                                            :value="userPassword"
                                            v-model="password"
                                            label="Crie uma senha"
                                            hint="Sua senha precisa conter no mín. 12 caracteres: maiúsculas, números e caracteres especiais (exemplo: @,!,#)"
                                            :append-icon="
                                                show1
                                                    ? 'mdi-eye'
                                                    : 'mdi-eye-off'
                                            "
                                            @click:append="show1 = !show1"
                                            :type="show1 ? 'text' : 'password'"
                                            :rules="passwordRules"
                                        >
                                            <template v-slot:progress>
                                                <v-progress-linear
                                                    v-if="custom"
                                                    :value="progress"
                                                    absolute
                                                    height="7"
                                                ></v-progress-linear> </template
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col sm="12" lg="3" xl="3">
                                        <v-card-text>
                                            <v-icon>mdi-lock</v-icon>
                                            Repetir senha:</v-card-text
                                        >
                                    </v-col>
                                    <v-col sm="12" lg="4" xl="4">
                                        <v-text-field
                                            solo
                                            name="Repita a nova senha"
                                            small
                                            label="Repita a nova senha"
                                            :type="show1 ? 'text' : 'password'"
                                            :append-icon="
                                                show1
                                                    ? 'mdi-eye'
                                                    : 'mdi-eye-off'
                                            "
                                            @click:append="show1 = !show1"
                                            data-cy="signinPasswordField"
                                            v-model="passwordConfirmation"
                                            :rules="passwordRulesConfirmation"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-card-actions>
                                    <v-btn
                                        color="primary"
                                        data-cy="signinSubmitBtn"
                                        :loading="load_troca_senha"
                                        @click="submit"
                                        :disabled="!valid"
                                    >
                                        Salvar
                                        <v-icon class="ml-1"
                                            >chevron_right</v-icon
                                        >
                                    </v-btn>
                                </v-card-actions>
                            </v-form>
                        </v-card-text>
                        <Snackbar
                            v-if="snackbar"
                            :mostrarSnackbar="snackbar"
                            :corSnackbar="snackbarColor"
                            :mensagemSnackbar="mensagemSnackbar"
                            @fecharSnackbar="snackbar = false"
                        />
                    </v-card>
                </v-tab-item>
            </v-tabs>
        </v-card>
        <v-layout align-center justify-center>
            <v-flex xs12 sm8 md6> </v-flex>
        </v-layout>

        <v-dialog v-model="dialogMensagemFinal" max-width="400">
            <v-card>
                <v-card-text class="pt-5 mb-n10">{{
                    resposta_requisicao
                }}</v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="primary"
                        text
                        @click="dialogMensagemFinal = !dialogMensagemFinal"
                        >ok</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import Vue from 'vue';
import SenhaService from '@/services/redefinirSenhaService';
import {
    validarSenha,
    validarStringSemEspaco,
    getMensagemRequest
} from '@/helpers/Utilitarios';

export default {
    name: 'MinhaConta',
    props: ['user_profile'],
    data() {
        return {
            custom: true,
            show1: false,
            value: '',
            userPassword: '',
            passwordRules: [
                v => !!v || 'Senha é requerida',
                v =>
                    this.validarSenha(v) ||
                    'Sua senha precisa conter no mín. 12 caracteres: maiúsculas, números e caracteres especiais (exemplo: @,!,#)',
                v =>
                    this.validarStringSemEspaco(v) ||
                    'Não é permitido utilizar espaços.'
            ],
            passwordRulesConfirmation: [
                v => !!v || 'Confirmar Senha é requerida',
                v => v == this.password || 'As senhas devem ser iguais',
                v =>
                    this.validarStringSemEspaco(v) ||
                    'Não é permitido utilizar espaços.'
            ],
            valid: false,
            dialogMensagemFinal: false,
            resposta_requisicao: '',
            load_troca_senha: false,
            password: '',
            passwordConfirmation: '',
            nomeExibicaoApresentante: null,
            linkContatoApresentante: null,
            snackbar: false,
            snackbarColor: null,
            diasLembrete102: null,
            diasSelecionados: null,
            dialogResponse: null,
            mensagemSnackbar: null,
            diasLembrete102Computed: null,
            startTab: "meusdados"
        };
    },
    methods: {
        validarSenha,
        validarStringSemEspaco,
        getMensagemRequest,
        submit() {
            this.load_troca_senha = true;

            return new Promise(() => {
                let senhaService = new SenhaService(Vue.http, this.$store);
                
                senhaService
                    .salvarSenha(
                        this.password,
                        this.userProfile.login,
                        this.userProfile.id_user,
                        this.userProfile.cd_apresentante
                    )
                    .then(
                        response => {
                            this.resposta_requisicao = response.body;
                            this.snackbar = true;
                            this.mensagemSnackbar = this.getMensagemRequest(
                                response.body,
                                'Senha alterada com sucesso!'
                            );
                            this.snackbarColor = 'green white--text';

                            this.show1 = false;
                            this.password = '';
                            this.passwordConfirmation = '';
                            this.$refs.form.resetValidation();

                            //deleta o controle de lembrete de expiracao de senha
                            localStorage.removeItem('LembreteExpiracaoSenha');

                            //Atualiza o os dias de expiração da senha do usuario com valor qualquer
                            let user = {...this.userProfile}
                            user['password_expire_in'] = 999
                            this.$store.commit('setUser', user);

                        },
                        error => {
                            this.snackbar = true;
                            this.mensagemSnackbar = this.getMensagemRequest(
                                error.body,
                                'Erro ao alterar senha.'
                            );
                            this.snackbarColor = 'red white--text';
                        }
                    )
                    .finally(() => {
                        this.load_troca_senha = false;
                    });
            });
        }
    },
    computed: {
        computedGetUserName() {
            return this.$store.getters.getUserName;
        },
        computedGetApresentante() {
            return this.$store.getters.cdApresentante;
        },
        computedGetUserEmail() {
            return this.$store.getters.getUserEmail;
        },
        computedGetUserLogin() {
            return this.$store.getters.getUserLogin;
        },
        computedGetSuperUser() {
            return this.$store.getters.getSuperUser;
        },
        progress() {
            return Math.min(100, this.password.length * 8.4);
        },
        userProfile() {
            return this.$store.getters.user;
        }
    },
    mounted() {
        let action = this.$route.params.action || null
        if ( action && action == 'alterar-senha' ) {
            this.startTab = 'seguranca'
        }
    }
};
</script>
